import Axios from "axios";
import Cookies from 'js-cookie'
import axios from "axios";
import {SERVER_URL} from "../config";

export const apiURL = `${SERVER_URL}/api/v1`
export const apiData = SERVER_URL

export const instance = Axios.create({
    baseURL: `${apiURL}`,
    headers: {
        'Content-Type': 'application/json',
    }
})

instance.interceptors.request.use(async config => {
    const token = Cookies.get('access-token') ?? ''
    config.headers.Authorization = token ? `JWT ${token}` : ''
    return config
})

instance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response?.status === 403 && (error.response.data.detail === 'Authentication credentials were not provided.' || error.response.data.detail === 'Token is not correct')) {
            originalRequest._retry = true
            Cookies.remove('access-token')
            return axios(originalRequest)
        }

        if (error.response?.status === 403) {
            Cookies.remove('access-token')
            Cookies.remove('refresh-token')
            window.location.reload()
        }

        return Promise.reject(error)
    }
)

export const getAPI = {
    login: loginData => {
        return instance.post('/auth/admin/', loginData)
    },
    updateToken: refresh => {
        return instance.post('/auth/refresh/', {refresh})
    },
    getUsers: (type, page = 1, orderBy = 'subscription', search = '', deleted = false) => {
        return instance.get(`/${type}/?page=${page}&order_by=${orderBy}&search=${search}&${deleted ? 'filters=is_deleted' : null}`)
    },
    banUser: (type, id) => {
        return instance.post(`/${type}/${id}/ban/`)
    },
    deleteUser: (type, id) => {
        return instance.delete(`/${type}/${id}/delete/`)
    },
    getAppeals: (page = 1, search = '', isAnswered = false) => {
        return instance.get(`/feedback/questions/?page=${page}&search=${search}&${isAnswered ? 'filters=is_answered' : ''}`)
    },
    sendAnswer: (question_id, description) => {
        return instance.post(`/feedback/answers/send/`, {question_id, description})
    },
    getArticles: (type, page = 1, search = '', section) => {
        let filters = 'is_published'
        if (type === 'Черновик')
            filters = 'is_draft'
        if (type === 'Отложенный')
            filters = 'is_pending'
        return instance.get(`/admin/articles/?page=${page}&filters=${filters}&search=${search}&sections=${section}`)
    },
    getArticleById: (id) => {
        return instance.get(`/admin/articles/${id}`)
    },
    getArticleSections: () => {
        return instance.get(`/articles/sections/`)
    },
    deleteArticle: (id) => {
        return instance.delete(`/admin/articles/${id}/delete`)
    },
    createArticle: () => {
        return instance.post(`/admin/articles/create/`)
    },
    updateArticle: (id, formData) => {
        return instance.put(`/admin/articles/${id}/update/`, formData, {
            headers: {
                'Content-Type': 'form-data'
            }
        })
    },
    publishArticle: (id) => {
        return instance.put(`/admin/articles/${id}/update/?publish=true`)
    },
    retrieveArticle: (id) => {
        return instance.put(`/admin/articles/${id}/update/?retrieve=true`)
    },
    getAdvices: (type, page = 1, search = '', section) => {
        let filters = 'is_published'
        if (type === 'Черновик')
            filters = 'is_draft'
        if (type === 'Отложенный')
            filters = 'is_pending'

        switch (section) {
            case '1':
                section = 'advice'
                break
            case '2':
                section = 'workshop'
                break
            case '3':
                section = 'test'
                break
        }
        return instance.get(`/admin/advices/?page=${page}&filters=${filters},${section}${search ? `&search=${search}` : ''}`)
    },
    getAdviceById: (id) => {
        return instance.get(`/admin/advices/${id}`)
    },
    deleteAdvice: (id) => {
        return instance.delete(`/admin/advices/${id}/delete`)
    },
    createAdvice: () => {
        return instance.post(`/admin/advices/create/`)
    },
    updateAdvice: (id, formData) => {
        return instance.put(`/admin/advices/${id}/update/`, formData, {
            headers: {
                'Content-Type': 'form-data'
            }
        })
    },
    getAdviceSections: () => {
        return instance.get(`/advices/sections/`)
    },
    publishAdvice: (id) => {
        return instance.put(`/admin/advices/${id}/update/?publish=true`)
    },
    retrieveAdvice: (id) => {
        return instance.put(`/admin/advices/${id}/update/?retrieve=true`)
    },
    getThemes: (type, page) => {
        return instance.get(`/forum/themes/${type}/?page=${page}`)
    },
    createTheme: formData => {
        return instance.post(`/forum/themes/`, formData)
    },
    deleteTheme: id => {
        return instance.delete(`/forum/themes/${id}/`)
    },
    getPosts: (id, page, status, full = false, search = '') => {
        let filters = 'is_published'
        if (status === 'Черновик')
            filters = 'is_draft'
        if (status === 'Отложенный')
            filters = 'is_pending'
        return instance.get(`/admin/forum/themes/${id}/posts/?page=${page}&filters=${filters}&full=${full}&search=${search}`)
    },
    createPost: theme => {
        return instance.post(`/forum/posts/create/`, {theme})
    },
    getPost: (id) => {
        return instance.get(`/forum/posts/${id}/`)
    },
    updatePost: (id, formData) => {
        return instance.put(`/admin/forum/posts/${id}/update/`, formData)
    },
    publishPost: (id) => {
        return instance.put(`/admin/forum/posts/${id}/update/?publish=true`)
    },
    retrievePost: (id) => {
        return instance.put(`/admin/forum/posts/${id}/update/?retrieve=true`)
    },
    deletePost: (id) => {
        return instance.delete(`/forum/posts/${id}/delete/`)
    },
    getRootComments: (id, page) => {
        return instance.get(`/forum/posts/${id}/comments/?page=${page}`)
    },
    getAnswerComments: (commentId) => {
        return instance.get(`/forum/comments/${commentId}/answer/?full=true`)
    },
    addRootComment: (postId, formData) => {
        return instance.post(`/forum/posts/${postId}/comments/`, formData)
    },
    addAnswerComment: (commentId, formData) => {
        return instance.post(`/forum/comments/${commentId}/answer/`, formData)
    },
    deleteComment: id => {
        return instance.delete(`/forum/comments/${id}/delete/`)
    },
    restoreComment: id => {
        return instance.post(`/forum/comments/${id}/restore/`)
    },
    getComplaints: (type, page) => {
        return instance.get(`/forum/complaints/?page=${page}&filters=${type}`)
    },
    getComplaintsTotal: () => {
        return instance.get(`/forum/complaints/count/`)
    },
    deleteComplaint: id => {
        return instance.delete(`/forum/complaints/${id}/`)
    },

    getExercises: (category, status, page, search) => {
        return instance.get(`/admin/practices/exercises/?status=${status}&page=${page}&search=${search}&category=${category}`)
    },

    getExercise: id => {
        return instance.get(`/admin/practices/exercises/${id}/`)
    },

    createExercise: () => {
        return instance.post(`/admin/practices/exercises/create/`)
    },

    updateExercise: (id, formData) => {
        return instance.put(`/admin/practices/exercises/${id}/update/`, formData)
    },

    publishExercise: id => {
        return instance.post(`/admin/practices/exercises/${id}/publish/`)
    },

    retrieveExercise: id => {
        return instance.put(`/admin/practices/exercises/${id}/update/?retrieve=true`)
    },

    deleteExercise: id => {
        return instance.delete(`/admin/practices/exercises/${id}/delete/`)
    },

    acceptExercise: id => {
        return instance.post(`/admin/practices/exercises/${id}/moderate/`, {accept: true})
    },

    rejectExercise: id => {
        return instance.post(`/admin/practices/exercises/${id}/moderate/`, {accept: false})
    },

    getExerciseCounter: () => {
        return instance.get(`/admin/practices/exercises/counters/`)
    },

    getSymptoms: (search, page, category = '', age = '') => {
        return instance.get(`/admin/practices/symptoms/?page=${page}&search=${search}&category=${category}&age=${age}`)
    },

    getSymptomById: id => {
        return instance.get(`/admin/practices/symptoms/${id}/`)
    },

    createSymptom: data => {
        return instance.post(`/admin/practices/symptoms/create/`, data)
    },

    editSymptom: (id, data) => {
        return instance.put(`/admin/practices/symptoms/${id}/update/`, data)
    },

    deleteSymptom: id => {
        return instance.delete(`/admin/practices/symptoms/${id}/delete`)
    },

    getTasks: (page, level, search) => {
        return instance.get(`/admin/practices/tasks/?page=${page}&level=${level}&search=${search}`)
    },
    getTask: id => {
        return instance.get(`/admin/practices/tasks/${id}/`)
    },
    createTask: data => {
        return instance.post(`/admin/practices/tasks/create/`, data)
    },
    updateTask: (id, data) => {
        return instance.put(`/admin/practices/tasks/${id}/update/`, data)
    },
    deleteTask: id => {
        return instance.delete(`/admin/practices/tasks/${id}/delete/`)
    },
    createLesson: data => {
        return instance.post(`/admin/practices/lessons/create/`, data)
    },
    deleteLesson: id => {
        return instance.delete(`/admin/practices/lessons/${id}/delete/`)
    },
    updateLesson: (id, data) => {
        return instance.patch(`/admin/practices/lessons/${id}/update/`, data)
    }

}
