import {Navigate, useLocation} from "react-router-dom";
import {useState} from "react";
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";

const AuthGuard = ({children}) => {

    const {isAuthenticated, isInitialized} = useAuth()

    const {pathname} = useLocation()

    const [requestedLocation, setRequestedLocation] = useState(null)

    if(!isInitialized) return <LoadingScreen/>

    if(!isAuthenticated){
        if(pathname !== requestedLocation){
            setRequestedLocation(pathname)
        }
        return <Navigate to='/auth'/>
    }

    if(requestedLocation && pathname !== requestedLocation){
        setRequestedLocation(null)
        return <Navigate to={requestedLocation}/>
    }

    return <>{children}</>
}

export default AuthGuard