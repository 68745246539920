import React from "react";
import classes from './index.module.sass'
import {ReactComponent as Logo} from "../../assets/Logo.svg"
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {menu} from "../../utils/dataUtils";
import useAuth from "../../hooks/useAuth";

const SidePanel = () => {
    const {logout} = useAuth()
    const {activePage} = useSelector(state => state.General)

    return (
        <div className={classes.container}>
            <div className={classes.container_logo}>
                <Logo/>
            </div>
            <div className={classes.container_menu}>
                {menu.map((item, index) => (
                        <Link to={`${item.link}`} className={clsx(classes.container_menu_item, item.title===activePage && classes.container_menu_item_active)} key={item.title + index}>
                            {item.icon()}
                            <span>{item.title}</span>
                        </Link>
                    )
                )}
                <div className={classes.container_menu_item} onClick={logout}>
                    <span>Выход</span>
                </div>
            </div>
        </div>
    )
}

export default SidePanel