import {
    ADVICES_SET_ADVICES, ADVICES_SET_DELETE, ADVICES_SET_PAGES, ADVICES_SET_RENDER,
} from "../types";

const AdvicesReducer =
    (
        state = {
            advices: [],
            pages: 1,
            render: 0
        }, action) => {
        switch (action.type) {
            case ADVICES_SET_ADVICES:
                return {
                    ...state,
                    advices: action.payload.advices
                }
            case ADVICES_SET_PAGES:
                return {
                    ...state,
                    pages: action.payload.pages
                }
            case ADVICES_SET_DELETE:
                return {
                    ...state,
                    advices: state.advices.filter(advice => advice.id !== action.payload.id)
                }
            case ADVICES_SET_RENDER:
                return {
                    ...state,
                    render: state.render + 1
                }
            default:
                return state
        }
    }

export default AdvicesReducer
