import React from "react";
import classes from './index.module.sass'
import {useSelector} from "react-redux";
import {ReactComponent as Back} from "../../assets/common/back_btn.svg";
import {useNavigate} from "react-router";

const Header = () => {

    const navigate = useNavigate()
    const {
        currentPage,
        headerItems,
        activePage,
        previousPage,
        previousPageHandler
    } = useSelector(state => state.General)

    const goBack = async () => {
        if (previousPageHandler) {
            await previousPageHandler()
        } else {
            previousPage ? navigate(previousPage) : navigate(-1)
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.container_title}>
                {currentPage !== activePage && <div className={classes.container_title_back} onClick={goBack}>
                    <Back/>
                </div>}
                <h1>{currentPage}</h1>
            </div>
            <div className={classes.container_items}>
                {headerItems?.length ? headerItems?.map(item => item && item()) : null}
            </div>
        </div>
    )
}

export default Header
