import {FEEDBACK_DELETE_APPEAL, FEEDBACK_SET_APPEALS, FEEDBACK_SET_PAGES} from "../types";


const FeedbackReducer =
    (
        state = {
            appeals: [],
            pages: 1
        }, action) => {
        switch (action.type){
            case FEEDBACK_SET_APPEALS:
                return {
                    ...state,
                    appeals: action.payload.appeals
                }
            case FEEDBACK_SET_PAGES:
                return {
                    ...state,
                    pages: action.payload.pages
                }
            case FEEDBACK_DELETE_APPEAL:
                return {
                    ...state,
                    appeals: state.appeals.filter(appeal => appeal.id !== action.payload.id)
                }
            default:
                return state
        }
    }

export default FeedbackReducer