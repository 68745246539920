import React from "react";
import classes from './LoadingScreen.module.sass'

const LoadingScreen = () => {

    return (
        <div className={classes.loading}>
            <h1>Загрузка</h1>
        </div>
    )
}

export default LoadingScreen
