export const GENERAL_SET_PAGE = 'GENERAL_SET_PAGE'
export const GENERAL_SET_ITEMS = 'GENERAL_SET_ITEMS'
export const GENERAL_SET_ACTIVE = 'GENERAL_SET_ACTIVE'
export const GENERAL_SET_PREVIOUS = 'GENERAL_SET_PREVIOUS'
export const GENERAL_SET_PREVIOUS_HANDLER = 'GENERAL_SET_PREVIOUS_HANDLER'

export const USERS_SET_USERS = 'USERS_SET_USERS'
export const USERS_SET_PAGES = 'USERS_SET_PAGES'
export const USERS_BAN = 'USERS_BAN'
export const USERS_DELETE = 'USERS_DELETE'

export const FEEDBACK_SET_APPEALS = 'FEEDBACK_SET_APPEALS'
export const FEEDBACK_SET_PAGES = 'FEEDBACK_SET_PAGES'
export const FEEDBACK_DELETE_APPEAL = 'FEEDBACK_DELETE_APPEAL'

export const ARTICLES_SET_ARTICLES = 'ARTICLES_SET_ARTICLES'
export const ARTICLES_SET_DELETE = 'ARTICLES_SET_DELETE'
export const ARTICLES_SET_PAGES = 'ARTICLES_SET_PAGES'
export const ARTICLES_SET_RENDER = 'ARTICLES_SET_RENDER'

export const ADVICES_SET_ADVICES = 'ADVICES_SET_ADVICES'
export const ADVICES_SET_DELETE = 'ADVICES_SET_DELETE'
export const ADVICES_SET_PAGES = 'ADVICES_SET_PAGES'
export const ADVICES_SET_RENDER = 'ADVICES_SET_RENDER'

export const FORUM_SET_THEMES = 'FORUM_SET_THEMES'
export const FORUM_SET_CURRENT_THEME = 'FORUM_SET_CURRENT_THEME'
export const FORUM_SET_PAGES = 'FORUM_SET_PAGES'
export const FORUM_SET_POSTS = 'FORUM_SET_POSTS'
export const FORUM_SET_RENDER = 'FORUM_SET_RENDER'

export const EXERCISES_SET_EXERCISES = 'EXERCISES_SET_EXERCISES'
export const EXERCISES_SET_RENDER = 'EXERCISES_SET_RENDER'
export const EXERCISES_SET_PAGES = 'EXERCISES_SET_PAGES'
