import {createContext, useEffect, useReducer} from "react";
import {getAPI, instance} from "../api/api";
import useCookie from "../hooks/useCookie";
import Cookies from "js-cookie";

const Types = {
    Initial: 'INITIALIZE',
    Login: 'LOGIN',
    Logout: 'LOGOUT',
    Render: 'RENDER'
}

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    render: 0
}

const AuthReducer = (state, action) => {
    switch (action.type) {
        case Types.Initial:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
            }
        case Types.Login:
            return {
                ...state,
                isAuthenticated: true,
            }
        case Types.Logout:
            return {
                ...state,
                isAuthenticated: false,
            }
        case Types.Render:
            return {
                ...state,
                render: state.render + 1
            }

        default:
            return state
    }
}

const AuthContext = createContext(null)

const AuthProvider = ({children}) => {
    const [accessToken, updateAccessToken, deleteAccessToken] = useCookie('access-token')
    const [refreshToken, updateRefreshToken, deleteRefreshToken] = useCookie('refresh-token')
    const [state, dispatch] = useReducer(AuthReducer, initialState)

    useEffect(() => {
        const initialize = async () => {
            try {
                if (accessToken) {
                    setSession(accessToken)
                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isAuthenticated: true,
                        }
                    })
                } else {
                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isAuthenticated: false,
                        }
                    })
                }
            } catch (e) {
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isAuthenticated: false,
                    }
                })
            }
        }

        initialize()
    }, [])

    useEffect(() => {
        if (state.isInitialized && !accessToken) {
            if (refreshToken) {
                const response = getAPI.updateToken(refreshToken).then(response => {
                    updateAccessToken(response.data.access)
                    updateRefreshToken(response.data.refresh)
                }).catch(error => logout())
            } else {
                logout()
            }
        }
    }, [accessToken])

    const setSession = accessToken => {
        if (accessToken) {
            updateAccessToken(accessToken)
            instance.defaults.headers.common.Authorization = `JWT ${accessToken}`
        } else {
            deleteAccessToken()
            delete instance.defaults.headers.common.Authorization
        }
    }

    const login = async (email, password) => {
        const response = await getAPI.login({email, password})

        if (response.status === 200) {
            const {access, refresh} = response.data

            setSession(access)
            updateRefreshToken(refresh)

            dispatch({type: Types.Login})
        }

        //TODO connect refresh token

        return response
    }

    const logout = async () => {
        setSession(null)
        dispatch({type: Types.Logout})
    }

    const setRender = () => {
        dispatch({type: Types.Render})
    }


    return (
        <AuthContext.Provider value={{...state, login, logout, setRender}}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider}