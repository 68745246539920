import {
    ARTICLES_SET_ARTICLES,
    ARTICLES_SET_DELETE,
    ARTICLES_SET_PAGES,
    ARTICLES_SET_RENDER
} from "../types";

const ArticlesReducer =
    (
        state = {
            articles: [],
            pages: 1,
            render: 0
        }, action) => {
        switch (action.type){
            case ARTICLES_SET_ARTICLES:
                return {
                    ...state,
                    articles: action.payload.articles
                }
            case ARTICLES_SET_PAGES:
                return {
                    ...state,
                    pages: action.payload.pages
                }
            case ARTICLES_SET_DELETE:
                return {
                    ...state,
                    articles: state.articles.filter(article => article.id !== action.payload.id)
                }
            case ARTICLES_SET_RENDER:
                return {
                    ...state,
                    render: state.render + 1
                }
            default:
                return state
        }
    }

export default ArticlesReducer
