import React, {lazy, Suspense} from "react";
import {useRoutes} from "react-router";
import LoadingScreen from "../components/LoadingScreen";
import GuestGuard from "../guards/GuestGuard";
import Layout from "../hoc/Layout";
import AuthGuard from "../guards/AuthGuard";
import {Navigate} from "react-router-dom";

const Loadable = Component => props => {

    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Component {...props}/>
        </Suspense>
    )
}

const Router = () => {

    return useRoutes([
        {
            path: 'auth',
            element: <GuestGuard>
                <Login/>
            </GuestGuard>
        },
        {
            path: 'dashboard',
            element: <AuthGuard>
                <Layout/>
            </AuthGuard>,
            children: [
                {element: <Navigate to='users' replace/>, index: true},
                {path: 'users', element: <Users/>},
                {path: 'exercises', element: <Exercises/>},
                {path: 'articles', element: <Articles/>},
                {path: 'articles/:id', element: <AddArticle/>},
                {path: 'feedback', element: <FeedBack/>},
                {path: 'forum', element: <Forum/>},
                {path: 'forum/:themeId/discussion/:id', element: <AddDiscussion/>},
                {path: 'forum/moderation/theme/:id', element: <SectionDiscussions/>},
                {path: 'forum/moderation/:id', element: <ForumModeration/>},
                {path: 'exercises/:type/:id', element: <AddExercise/>},
                {path: 'exercises/moderation', element: <ExerciseModeration/>},
                {path: 'forum/complaints', element: <ForumComplaints/>},
                {path: 'advices', element: <Advices/>},
                {path: 'advices/:id', element: <AddAdvice/>},
                {path: 'symptoms', element: <Symptoms/>},
                {path: 'tasks', element: <Tasks/>},
                {path: 'tasks/:id/update', element: <AddTask pageType='edit'/>},
                {path: 'tasks/create', element: <AddTask pageType='create'/>}
            ]
        },
        {
            path: '/',
            element: <Navigate to='/dashboard/users' replace/>,
            index: true
        },
        {
            path: '*',
            element: <Navigate to='/dashboard/users' replace/>
        }
    ])
}

export default Router

const Login = Loadable(lazy(() => import('../pages/Login')))
const Users = Loadable(lazy(() => import('../pages/Users')))
const Exercises = Loadable(lazy(() => import('../pages/Exercises')))
const Articles = Loadable(lazy(() => import('../pages/Articles')))
const AddArticle = Loadable(lazy(() => import('../pages/AddArticle')))
const FeedBack = Loadable(lazy(() => import('../pages/FeedBack')))
const Forum = Loadable(lazy(() => import('../pages/Forum')))
const AddDiscussion = Loadable(lazy(() => import('../pages/AddDiscussion')))
const SectionDiscussions = Loadable(lazy(() => import('../pages/SectionDiscussions')))
const ForumModeration = Loadable(lazy(() => import('../pages/ForumModeration')))
const AddExercise = Loadable(lazy(() => import('../pages/AddExercise')))
const ExerciseModeration = Loadable(lazy(() => import('../pages/ExerciseModeration')))
const ForumComplaints = Loadable(lazy(() => import('../pages/ForumComplaints')))
const Advices = Loadable(lazy(() => import('../pages/Advices')))
const AddAdvice = Loadable(lazy(() => import('../pages/AddAdvice')))
const Symptoms = Loadable(lazy(() => import('../pages/Symptoms')))
const Tasks = Loadable(lazy(() => import('../pages/Tasks')))
const AddTask = Loadable(lazy(() => import('../pages/AddTask')))

