import {
    FORUM_SET_CURRENT_THEME,
    FORUM_SET_PAGES,
    FORUM_SET_POSTS,
    FORUM_SET_RENDER,
    FORUM_SET_THEMES
} from "../types";

const ForumReducer =
    (
        state = {
            themes: [],
            currentTheme: '',
            posts: {},  // {0 : [], 1 : [], 2 : []}
            render: 0,
            pages: 1,
        }, action) => {
        switch (action.type){
            case FORUM_SET_THEMES:
                return {
                    ...state,
                    themes: action.payload.themes
                }
            case FORUM_SET_CURRENT_THEME:
                return {
                    ...state,
                    currentTheme: action.payload.currentTheme
                }
            case FORUM_SET_POSTS:
                return {
                    ...state,
                    posts: {...state.posts, [action.payload.id]:action.payload.posts}
                }
            case FORUM_SET_RENDER:
                return {
                    ...state,
                    render: state.render + 1
                }
            case FORUM_SET_PAGES:
                return {
                    ...state,
                    pages: action.payload.pages
                }
            default:
                return state
        }
    }

export default ForumReducer
