import React from "react";
import classes from './index.module.sass'
import SidePanel from "../../components/SidePanel";
import Header from "../../components/Header";
import {Outlet} from "react-router";

const Layout = () => {
    return (
        <div className={classes.container}>
            <div className={classes.container_left}>
                <SidePanel/>
            </div>
            <div className={classes.container_right}>
                <Header/>
                <div className={classes.container_right_content}>
                    <Outlet/>
                </div>
            </div>

        </div>
    )
}

export default Layout