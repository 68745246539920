import {
    GENERAL_SET_ACTIVE,
    GENERAL_SET_ITEMS,
    GENERAL_SET_PAGE,
    GENERAL_SET_PREVIOUS,
    GENERAL_SET_PREVIOUS_HANDLER
} from "../types";

const GeneralReducer =
    (
        state = {
            currentPage:'',
            activePage:'',
            headerItems:[],
            previousPage: '',
            previousPageHandler: () => {},
        }, action) => {
        switch (action.type){
            case GENERAL_SET_ACTIVE:
                return {
                    ...state,
                    activePage: action.payload.activePage
                }
            case GENERAL_SET_PAGE:
                return {
                    ...state,
                    currentPage: action.payload.currentPage
                }
            case GENERAL_SET_ITEMS:
                return {
                    ...state,
                    headerItems: action.payload.headerItems
                }
            case GENERAL_SET_PREVIOUS:
                return {
                    ...state,
                    previousPage: action.payload.previousPage
                }
            case GENERAL_SET_PREVIOUS_HANDLER:
                return {
                    ...state,
                    previousPageHandler: action.payload.previousPageHandler
                }
            default:
                return state
        }
    }

export default GeneralReducer
