import {USERS_BAN, USERS_DELETE, USERS_SET_PAGES, USERS_SET_USERS} from "../types";

const UsersReducer =
    (
        state = {
            users: [],
            pages: 1
        }, action) => {
        switch (action.type){
            case USERS_DELETE:
                return {
                    ...state,
                    users: state.users.filter(user => user.id !== action.payload.id)
                }
            case USERS_BAN:
                return {
                    ...state,
                    users: state.users.map(user => user.id === action.payload.id ? {...user, is_banned:action.payload.ban} : user)
                }
            case USERS_SET_PAGES:
                return {
                    ...state,
                    pages: action.payload.pages
                }
            case USERS_SET_USERS:
                return {
                    ...state,
                    users: action.payload.users
                }

            default:
                return state
        }
    }

export default UsersReducer