import {EXERCISES_SET_PAGES, EXERCISES_SET_EXERCISES, EXERCISES_SET_RENDER} from "../types";

const ExercisesReducer =
    (
        state = {
            exercises: [],
            pages: 1,
            render: 0
        }, action) => {
        switch (action.type) {
            case EXERCISES_SET_EXERCISES:
                return {
                    ...state,
                    exercises: action.payload.exercises
                }
            case EXERCISES_SET_RENDER:
                return {
                    ...state,
                    render: state.render + 1
                }
            case EXERCISES_SET_PAGES:
                return {
                    ...state,
                    pages: action.payload.pages
                }
            default:
                return state
        }
    }

export default ExercisesReducer
