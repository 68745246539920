import React from "react";
import Router from "./routes";

const App = () => {

    return (
        <Router/>
    );
}

export default App;
