import {combineReducers} from "redux";
import GeneralReducer from "./GeneralReducer";
import UsersReducer from "./UsersReducer";
import FeedbackReducer from "./FeedbackReducer";
import ArticlesReducer from "./ArticlesReducer";
import ForumReducer from "./ForumReducer";
import AdvicesReducer from "./AdvicesReducer";
import ExercisesReducer from "./ExercisesReducer";

export default combineReducers({
    General: GeneralReducer,
    Users: UsersReducer,
    Feedback: FeedbackReducer,
    Articles: ArticlesReducer,
    Forum: ForumReducer,
    Advices: AdvicesReducer,
    Exercises: ExercisesReducer
})
