import {ReactComponent as Smile} from "../assets/sidepanel/smile.svg";
import {ReactComponent as Article} from "../assets/sidepanel/article.svg";
import {ReactComponent as Practices} from "../assets/sidepanel/practices.svg";
import {ReactComponent as Moderation} from "../assets/sidepanel/moderation.svg";
import {ReactComponent as Comment} from "../assets/sidepanel/comment.svg";
import React from "react";

export const menu = [
    {title: 'Пользователи', icon: () => <Smile/>, link: '/dashboard/users'},
    {title: 'Работа с симптомами', icon: () => <Article/>, link: '/dashboard/symptoms'},
    {title: 'Работа с заданиями', icon: () => <Practices/>, link: '/dashboard/exercises'},
    {title: 'Работа с задачами', icon: () => <Practices/>, link: '/dashboard/tasks'},
    {title: 'Работа со статьями', icon: () => <Article/>, link: '/dashboard/articles'},
    {title: 'Магазин', icon: () => <Article/>, link: '/dashboard/advices'},
    {title: 'Модерация форума', icon: () => <Moderation/>, link: '/dashboard/forum'},
    {title: 'Обратная связь', icon: () => <Comment/>, link: '/dashboard/feedback'},
]

export const patterns = {
    date: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    time: /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/
}

export const articleFields = [
    {name:'title', defaultValue: '', required: true},
    {name:'description', defaultValue: '', required: true},
    {name:'section', defaultValue: '', required: true},
    {name:'text', defaultValue: '', required: true},
    {name:'image', defaultValue: '', required: false},
    {name:'status', defaultValue: '', required: true},
    {name:'date', defaultValue: '', required: false},
    {name:'time', defaultValue: '', required: false},
]

export const adviceFields = [
    {name:'title', defaultValue: '', required: true},
    {name:'description', defaultValue: '', required: true},
    {name:'section', defaultValue: '', required: true},
    {name:'ages', defaultValue: '', required: true},
    {name:'cost', defaultValue: '', required: true},
    {name:'text', defaultValue: '', required: true},
    {name:'image', defaultValue: '', required: false},
    {name:'file', defaultValue: '', required: false},
    {name:'status', defaultValue: '', required: true},
    {name:'date', defaultValue: '', required: false},
    {name:'time', defaultValue: '', required: false},
]

export const exerciseDifficulties = [
    {id: 1, name: 'Простой', value: 'easy'},
    {id: 2, name: 'Средний', value: 'medium'},
    {id: 3, name: 'Сложный', value: 'hard'},
    // 'easy_level': 'Простой',
    // 'medium_level': 'Средний',
    // 'hard_level': 'Сложный'
]

export const symptomCategories = [
    {
        id: 1,
        name: 'Речь'
    },
    {
        id: 2,
        name: 'Моторика'
    },
    {
        id: 3,
        name: 'Поведение'
    },
    {
        id: 4,
        name: 'Эмоции и самоконтроль'
    },
    {
        id: 5,
        name: 'Высшие психические функции'
    }
]

export const adviceSectionOptions = {
    'workshop': 'Мастер-классы',
    'advice': 'Советы по развитию детей',
    'test': 'Тесты для развития детей'
}

export const ageCategories = [
    {id: 1, value: 'от 6 месяцев до 4 лет'},
    {id: 2, value: 'от 4 до 7 лет'},
    {id: 3, value: 'от 7 до 12 лет'},
]

export const forumDiscussionFields = [
    {name: 'title', defaultValue: '', required: true},
    {name: 'content', defaultValue: '', required: true},
    {name: 'anchor', defaultValue: false, required: true},
    {name: 'image', defaultValue: '', required: false},
    {name: 'date', defaultValue: '', required: false},
    {name: 'time', defaultValue: '', required: false},
    {name: 'status', defaultValue: 'is_draft', required: true},
]

export const forumComments = [
    {
        id: '1',
        parentId: '',
        userName: 'Mr.Professor',
        role: 'Администратор',
        date: '2021-12-25',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        commentsId: ['2', '3', '5']
    },
    {
        id: '2',
        parentId: '1',
        userName: 'Mr.Specialist',
        role: 'Специалист',
        date: '2021-12-25',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        commentsId: []
    },
    {
        id: '3',
        parentId: '1',
        userName: 'Mr.Roditel',
        role: 'Родитель',
        date: '2021-12-25',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        commentsId: ['4']
    },
    {
        id: '4',
        parentId: '3',
        userName: 'Mr.Roditel',
        role: 'Родитель',
        date: '2021-12-25',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
        commentsId: []
    },
    {
        id: '5',
        parentId: '3',
        userName: 'Mr.Roditel',
        role: 'Родитель',
        date: '2021-12-25',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
        commentsId: []
    },
]
